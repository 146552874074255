
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "b-sheet" })
export default class BSheet extends Vue {
  @Prop(Boolean) readonly fillHeight!: boolean;
  @Prop(Boolean) readonly depressed!: boolean;

  get css(): Record<string, any> {
    return {
      "fill-height": this.fillHeight,
      depressed: this.depressed,
    };
  }
}
